@use './variables'as var;
@use './include-media'as media;

@mixin toRem($property, $value, $value2: xxx, $value3: xxx, $value4: xxx) {
    @if $value2 !=xxx and $value3 !=xxx and $value4 !=xxx {
        #{$property}: calc($value / #{var.$global-font-size}) + rem
                      calc($value2 / #{var.$global-font-size}) + rem
                      calc($value3 / #{var.$global-font-size}) + rem
                      calc($value4 / #{var.$global-font-size}) + rem;
    }

    @else if $value2 !=xxx and $value3 !=xxx {
        #{$property}: calc($value / #{var.$global-font-size}) + rem
                      calc($value2 / #{var.$global-font-size}) + rem
                      calc($value3 / #{var.$global-font-size}) + rem;
    }

    @else if $value2 !=xxx {
        #{$property}: calc($value / #{var.$global-font-size}) + rem
                      calc($value2 / #{var.$global-font-size}) + rem;
    }

    @else {
        #{$property}: calc($value / #{var.$global-font-size}) + rem;
    }
}

@mixin toWrap() {
    margin-right: auto;
    margin-left: auto;
    max-width: var.$wrapper-max-width;
    padding-right: 20px;
    padding-left: 20px;

    @include media.media('>=desktop') {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@mixin toCenter() {
    display: flex;
    align-items: center;
}

@mixin rectangleShadow($size: 12) {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.1);
        transition: all 0.25s ease-out;
    }

    &:hover:before {
        @include toRem(top, $size);
        @include toRem(left, $size);
    }
}
