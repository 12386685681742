* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // user-select: none;

  &:active,
  :focus {
    outline: none !important;
  }
}

ul, ol {
  list-style: none;
}
