@use '@angular/material' as mat;

@import 'node_modules/ngx-toastr/toastr';
@import '~@angular/cdk/overlay-prebuilt.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@adyen/adyen-web/styles/adyen.css';

@import './variables';
@import './global';
@import './reset';
@import './include-media';
@import './toast';
@import 'swiper/swiper-bundle.min.css';

@include mat.core();