@use './mixing'as mix;
@use 'include-media' as media;
@use 'variables'as var;

//global font
* {
  font-family: 'Archivo', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    padding:
    env(safe-area-inset-top)
    env(safe-area-inset-right)
    env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

.wrapper {
    @include mix.toWrap();
}

//typography
h1 {
  font-family: 'Archivo', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;

  @include media.media('=<tablet') {
    font-size: 32px;
    font-weight: 800;
    line-height: 36px;
  }

  @include media.media('=<desktop') {
    font-size: 40px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
}

h2 {
  font-family: 'Archivo', sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: -0.02em;
}

h3 {
  font-family: 'Archivo', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;

  @include media.media('=<tablet') {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
  }


  @include media.media('=<desktop') {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
}

.subtitle {
  font-family: 'Archivo', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.body-one {
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &__bold {
    font-family: 'Archivo', sans-serif;
    font-weight: 700;
  }
}

.body-two {
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &__bold {
    font-family: 'Archivo', sans-serif;
    font-weight: 700;
  }
}

.body-three {
  font-family: 'Archivo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &__bold {
    font-family: 'Archivo', sans-serif;
    font-weight: 700;
  }
}


a {
  color: var.$color-black;
  text-decoration: none;
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

.adyen-checkout__button--pay {
  background: var.$color-green;
  border-radius: 8px;
  color: var.$color-black;

  &:hover {
    background: var.$color-hover-green;
  }
}

.adyen-checkout__button__icon {
  display: none;
  visibility: hidden;
}

.adyen-checkout__card__brands {
  position: absolute !important;
  top: -30px;
  right: 0;
}

.adyen-checkout__error-text {
  color: var.$color-error-red;
}

.adyen-checkout__label__text {
  color: var.$color-black;
}

.adyen-checkout__label__text--error {
  color: var.$color-error-red;
}

.adyen-checkout-input__inline-validation--invalid {
  color: var.$color-error-red;
}

.adyen-checkout__input {
  position: relative;
  background: #F5F5F7;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var.$color-black;
  border: none;
  width: 100%;

  @include media.media('>=desktop') {
      line-height: 20px;
      background: var.$color-white;
      border: 1px solid rgba(0, 79, 90, 0.5);
  }

  &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #96969A;
      opacity: 1;

      @include media.media('>=desktop') {
          line-height: 20px;
          color: var.$color-black;
          opacity: 0.5;
      }
  }
}