// color palette
$color-green: #00D973;
$color-cobalt-blue: #040309;
$color-sky-blue: #61C8FF;
$color-pink: #FF67A8;
$color-white: #FFFFFF;
$color-black: #040309;
$color-gray: #68686B;
$color-cobalt: #1440CC;

// hover
$color-hover-green: #13EA85;
$color-hover-cobalt: #2351E2;

// Notifications
$color-error-red: #DF3131;
$color-warning-orange: #FFAB00;
$color-success-green: #008855;

// spacing
$spacing-xxl: '64px';
$spacing-xl: '32px';
$spacing-l: '24px';
$spacing-m: '16px';
$spacing-s: '8px';
$spacing-xs: '4px';

// navigation
$nav-height: 129px;
$nav-height--mobile: 94px;


//fonts
@font-face {
  font-family: 'Archivo';
  src: url('../font/Archivo-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('../font/Archivo-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('../font/Archivo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

//transition time
$transition-time: 200ms;

//font size
$global-font-size: 16;

// others
$wrapper-max-width: 1200px;
$wrapper-wide-max-width: 1600px;
