@use './mixing'as mixing;
@use './variables'as var;
@use './include-media'as media;

body>.overlay-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  pointer-events: none;
}

.toast-container.mg-toast {
  bottom: 10px;
  left: 20px;
  right: 20px;

  @include media.media('>=desktop') {
    bottom: 32px;
    right: 32px;
  }

  .ngx-toastr {
    width: 100%;
    margin: 0 auto calc(10px + 63px + env(safe-area-inset-bottom));
    padding-left: 46px;
    border-radius: 8px;
    box-shadow: 0px 10px 30px rgba(80, 35, 20, 0.1);
    position: relative;

    &:hover {
      box-shadow: 0px 10px 30px rgba(80, 35, 20, 0.1);
    }

    @include media.media('>=desktop') {
      width: 385px;
      margin: 0 0 10px auto;
    }

    &.toast-error {
      background: var.$color-error-red;
      color: var.$color-white;

      &:before {
        content: url('/assets/icons/icon-error.svg');
        position: absolute;
        left: 18px;
        top: 17px;
      }
    }

    &.toast-warning {
      background: var.$color-warning-orange;
      color: var.$color-white;

      &:before {
        content: url('/assets/icons/icon-warrning.svg');
        position: absolute;
        left: 18px;
        top: 17px;
      }
    }

    &.toast-success {
      background: var.$color-success-green;
      color: var.$color-white;

      &:before {
        content: url('/assets/icons/icon-ok.svg');
        position: absolute;
        left: 18px;
        top: 17px;
      }
    }
  }
}